import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import items from './Dropdown/dropDownItems.js';
import CustomDropdown from './Dropdown/dropdown.jsx';
import logo from '../../assets/logos/aicraftLogoBlueWhite.svg';
import './navbar.css';

const getLevelKeys = (items1) => {
  const key = {};
  const func = (items2, level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

const levelKeys = getLevelKeys(items);
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [stateOpenKeys, setStateOpenKeys] = useState(['0', '0']);

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  window.onscroll = () => {
    const navbar = document.querySelector('.gpt3__navbar');
    if (window.scrollY > 10) {
      navbar.style.backgroundColor = 'rgba(4, 12, 24, 0.6)';
    } else {
      navbar.style.backgroundColor = 'transparent';
    }
  };

  // console.log({ items });

  return (
    <div className="gpt3__navbar">
      {/* <div className="gpt3__navbar-links"> */}
      <div className="gpt3__navbar-links_logo">
        <Link to="/">
          <img src={logo} />
        </Link>
      </div>
      {/* </div> */}
      <div className="gpt3__navbar-links_container">
        <CustomDropdown title="Servicios" items={items[0].children} />
        {/* <p>
          <Link to="/industries">
            <span className="dropdownButtonTitle">Industrias</span>
          </Link>
        </p> */}
        {/* <CustomDropdown title="Productos" items={items[1].children} />
        <CustomDropdown title="Entrenamiento" items={items[2].children} />
        <CustomDropdown title="Recursos" items={items[4].children} /> */}
        <p>
          <Link to="/contact">
            <span className="dropdownButtonTitle">Contacto</span>
          </Link>
        </p>
        {/* <CustomDropdown title="Nosotros" items={items[5].children} /> */}
      </div>

      <div className="gpt3__navbar-sign">
        <button type="button">Inicia Sesión</button>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <Menu
              mode="inline"
              defaultSelectedKeys={[]}
              openKeys={stateOpenKeys}
              onOpenChange={onOpenChange}
              style={{
                width: 256,
              }}
              items={items}
              theme="dark"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
