const advantagesData = [
  {
    key: 1,
    title: 'Expertos a tu servicio',
    text: 'Nada de personas de TI u otras áreas que se volvieron expertos en IA y ciencia de datos de la noche a la mañana. En Aicraft tendrás expertos reales para asegurar el éxito de tus proyectos.',
    text2: 'Diferente a otras compañias, Aicraft cuenta con expertos reales en ciencia de datos, AI, Business Intelligence, y procesos de datos para bridnarte los mejores resultados.',
  },
  {
    key: 2,
    title: 'Soluciones para ti',
    text: 'Ofrecemos soluciones personalizadas diseñadas específicamente para cumplir con tus metas y objetivos.',
    text2: '',
  },
  {
    key: 3,
    title: 'Enfocados al negocio',
    text: 'Nuestro equipo tiene un gran conocimiento técnico, pero también en desarrollo de proyectos e implementación de estas tecnologías enfocados a los negocios.',
    text2: '',
  },
  {
    key: 4,
    title: 'Innovación para el éxito',
    text: 'Aicraft se encarga de investigar y crear nuevos métodos y soluciones para innovar en todas las áreas para un mayor impacto de negocio.',
    text2: '',
  },
  {
    key: 5,
    title: 'Conocimiento regulatorio',
    text: 'Comprendemos las normativas y requisitos específicos de México y Latinoamérica, lo que nos permite implementar soluciones que cumplen con todos los estándares legales y éticos.',
    text2: '',
  },
];

export default advantagesData;
