import { React, useState } from 'react';
import AOS from 'aos';
import { Segmented } from 'antd';
import './Guidance.css';
import GuidanceCardData from './GuidanceCardData.js';
import GuidanceCard from './cards/GuidanceCard.jsx';
import 'aos/dist/aos.css';

AOS.init();

function Guidance() {
  const [selectedSection, setSelectedSection] = useState(GuidanceCardData.diagnostico);

  return (
    <div className="guidanceContainer">
      <p className="sectionTitle">Transformamos Datos en <span className="highlightedText">Decisiones Inteligentes</span></p>
      <p className="guidanceDesc sectionDescription">
        Cada empresa es única, con desafíos y metas propios. En Aicraft, nuestros servicios de consultoría se adaptan a la medida de sus necesidades, ofreciendo soluciones que impulsan resultados tangibles y asegurando el éxito a través de una guía experta en áreas clave que necesites.
      </p>
      <div
        className="mw-90"
        data-aos="fade-up"
        data-aos-offset="100"
        data-aos-delay="50"
        data-aos-duration="700"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <Segmented
          options={['Diagnóstico', 'Planeación y Gestión', 'Desarrollo', 'Implementación', 'Evaluación', 'Optimización']}
          onChange={(value) => {
            console.log(value);
            switch (value) {
              case 'Diagnóstico':
                setSelectedSection(GuidanceCardData.diagnostico);
                break;
              case 'Planeación y Gestión':
                setSelectedSection(GuidanceCardData.planeacion);
                break;
              case 'Desarrollo':
                setSelectedSection(GuidanceCardData.desarrollo);
                break;
              case 'Implementación':
                setSelectedSection(GuidanceCardData.implementacion);
                break;
              case 'Evaluación':
                setSelectedSection(GuidanceCardData.evaluacion);
                break;
              case 'Optimización':
                setSelectedSection(GuidanceCardData.optimizacion);
                break;
              default:
                setSelectedSection(GuidanceCardData.diagnostico);
                break;
            }
          }}
        />
        <GuidanceCard data={selectedSection} />
      </div>
    </div>
  );
}

export default Guidance;
