import React from 'react';
import AOS from 'aos';
import AdvantageCard from './AdvantageCard.jsx';
import advantagesData from './AdvantagesData.js';
import './Advantages.css';
import 'aos/dist/aos.css';

AOS.init();

const Advantages = () => (
  <div>
    <div className="advantagesContainer">
      <div className="advantagesContentContainer">
        <div className="advantagesTitleContainer">
          <span
            className="gradient__text-purple"
            style={{ fontWeight: 'bold', fontSize: '1.15rem' }}
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="50"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            AICRAFT Y LA COMPETENCIA
          </span>
          <span
            className="sectionTitle"
            data-aos="fade-zoom-in"
            data-aos-offset="100"
            data-aos-delay="300"
            data-aos-duration="700"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="true"
          >
            ¿Por qué elegir Aicraft?
          </span>
          <div className="dividerGradientBar" />
        </div>
        <span
          style={{ display: 'inline-block' }}
          className="sectionDescription"
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-delay="50"
          data-aos-duration="400"
          data-aos-easing="ease-in"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          Con un profundo entendimiento del contexto global en la adopción de las soluciones de IA, datos y analitica, nuestra experiencia en tecnologías de vanguardia y conocimientos específicos de la industria, Aicraft es el socio ideal para las empresas que buscan liderar en su mercado en la actualidad y el futuro
        </span>
        <div
          className="advantageCardWrapper"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="100"
          data-aos-duration="400"
          data-aos-easing="ease-in"
          data-aos-mirror="true"
          data-aos-once="true"
        >
          {advantagesData.map((item) => (
            <AdvantageCard key={item.key} title={item.title} text={item.text} text2={item.text2} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Advantages;
