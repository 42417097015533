import React from 'react';
import { Carousel, ConfigProvider } from 'antd';
import './Carousel.css';

const CarouselComponent = ({ children, dotsEnabled = true, effect = 'scrollx' }) => (
  <ConfigProvider
    theme={{
      components: {
        Carousel: {
          dotWidth: 10,
          dotHeight: 10,
          dotActiveWidth: 10,
        },
      },
    }}
  >
    <div className="w-screen h-80 carouselComponent">
      <Carousel arrows infinite dots={dotsEnabled} dotPosition="top" effect={effect} autoplay autoplaySpeed={4500}>
        { children }
      </Carousel>
    </div>
  </ConfigProvider>
);

export default CarouselComponent;
